import React, { useEffect, useState } from "react";

import { Pie } from "@ant-design/plots";
import axios from "axios";
import { Tag } from "antd";
const Dashboard = () => {
  const [data, setData] = useState([]);
  const [dataExpired, setdataExpired] = useState([]);
  const [dataStatus, setdataStatus] = useState([]);
  const [count, setCount] = useState(0);
  const fetch = () => {
    axios
      .get("https://activatewm.com/demo/v1/license/devices")
      .then((res) => {
        setCount(res.data.count);
        setData([
          {
            type: "Used",
            value: res.data.used,
          },
          {
            type: "Unused",
            value: parseInt(res.data.count) - parseInt(res.data.used),
          },
        ]);
        setdataExpired([
          {
            type: "Expired",
            value: res.data.expired,
          },
          {
            type: "Unexpired",
            value: parseInt(res.data.count) - parseInt(res.data.expired),
          },
        ]);
        setdataStatus([
          {
            type: "Enabled",
            value: res.data.count - res.data.status,
          },
          {
            type: "Disabled",
            value: res.data.status,
          },
        ]);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetch();
  }, []);
  const config = {
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "tooltip", enable: true }, { type: "" }],
  };
  return (
    <div>

        <div className="dashboard-header">
        <b className="companyTitle">
          Total devices
        </b>
        <Tag
           className="tag"
          color={"cyan"}
         
        >
          {count}
        </Tag>
        </div>
      <div className="row2">
        <div className="chart-size">
          <Pie {...config} data={dataExpired} />
        </div>
        <div className="chart-size">
          <Pie {...config} data={data} />
        </div>
        <div className="chart-size">
          <Pie {...config} data={dataStatus} />
        </div>
      </div>
      <b className="companyTitle" style={{marginLeft:'10%',textAlign:'center'}}>
        AGS Advanced Inc.
      </b>
    </div>
  );
};
export default Dashboard;
