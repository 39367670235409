import React,{useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {PlusOutlined,CheckOutlined,EditFilled,DeleteFilled} from '@ant-design/icons'
import { Empty, Input, message, Pagination, Tag, Tooltip} from "antd";
import axios from "axios";
import moment from "moment";
import "antd/dist/antd.css";

const style = { width:120,marginRight:40,paddingLeft:10};
const style3={width:70,textAlign:'center',fontSize:16,backgroundColor:'#dd4',marginRight:10,borderRadius:20,cursor:'pointer'}
const Devices=()=>{
    const location=useLocation()
    const navigation=useNavigate()
    const [min,setMin]=useState(0)
    const [max,setMax]=useState(4)
    const pageSize=4
    
    const[data,setData]=useState([])
    const[temp,setTemp]=useState([])
    const clientID=location.state.c_id
    const dealerID=location.state.d_id
    const prams=()=>{
       return clientID.length==0?'dealer_id='+dealerID:"client_id="+clientID
    }
  const fetch=()=>{
    const hide = message.loading('Action in progress..', 0);
    axios.get('https://activatewm.com/demo/v1/license/get?'+prams())
    .then(res=>{
      setTimeout(hide,100)
      setData(res.data)
      setTemp(res.data)
      console.log(res.data)
    })
    .catch(err=>{
      setTimeout(hide,100)
      message.error('internal error')
    })
  }

  const inc=(type,amount)=>{

   axios.put('https://activatewm.com/demo/v1/license/increment',{
    "dealer_id":dealerID,  
    "customer_id":clientID,
    "type":type,
    "amount":amount
   }).then(res=>{
     if(res.data.success){
      message.success("Incremented successfully ",0.5)
      fetch()
     }else{
       message.error('please try again',0,5)
     }
   }).catch(err=>{
    message.error('internal error',0.5)
  })
  }
  const onSearch = value => {
    if(value.length==0){
      setData(temp)
    }else{
     let matched=temp.filter((item)=>{
        if(item.license_name.match(value)||item.code.match(value)){
          return item
        }
      })
      setData(matched)
    }
  }; 
  useEffect(()=>{
   fetch()
  },[])

    return(
     <div>
     <div className="header">
      <h5>Devices</h5>
      <div className="create">
      <PlusOutlined className='plus' onClick={()=>{
        navigation('/license/create',{
          state:{
            c_id:location.state.c_id,
            d_id:location.state.d_id
          }  
        })
        }} />
      <Input.Search placeholder="search title/code" allowClear onSearch={onSearch} style={{ width: 200 }} />
      </div>
      </div>
      <div>
        <div className="row2" style={{marginTop:50,width:'100%',justifyContent:'space-between'}}>
        <b className="increase" onClick={()=>{
          if(data.length==0){
            message.warn('no device found')
          }else{
            inc('months',1)
          }
        }}>
          Increase one month to all devices</b>
        <b className="increase" onClick={()=>{
if(data.length==0){
  message.warn('no device found')
}else{
  inc('months.',2)
}
        }}>
          Increase two month to all devices</b>
          <b className="increase" onClick={()=>{
if(data.length==0){
  message.warn('no device found')
}else{
  inc('months',3)
}
        }}>
          Increase three month to all devices</b>  
          <b className="increase" onClick={()=>{
if(data.length==0){
  message.warn('no device found')
}else{
  inc('months',4)
}
        }}>
          Increase four month to all devices</b>  
          <b className="increase" onClick={()=>{
if(data.length==0){
  message.warn('no device found')
}else{
  inc('months',5)
}
        }}>
          Increase five month to all devices</b>  
          <b className="increase" onClick={()=>{
if(data.length==0){
  message.warn('no device found')
}else{
  inc('months',6)
}
        }}>
          Increase six month to all devices</b> 
          <b className="increase" onClick={()=>{
if(data.length==0){
  message.warn('no device found')
}else{
  inc('years',1)
}
        }}>
          Increase one year to all devices</b> 
        </div>
        {
          data.length==0?<Empty/>:
          <>
          <div className="card" style={{marginBottom:5}}>
       {
        data.slice(min,max).map((v,i)=>{
          return   <div 
          className="client-card w3-card "
          style={{ width: 330,height:530,padding:10,margin:10}}>
          
          <div className="row">
            <b style={style}> License Name :</b>
            <p>{v.license_name}</p>
          </div>
          <div className="row">
            <b style={style}> Device Type :</b>
            <p>{v.devicesType.name}</p>
          </div>
          <div className="row">
            <b style={style}> Software Type :</b>
            <p>{v.softwaretype?v.softwaretype.name:""}</p>
          </div>
          
          <div className="row">
            <b style={style}> License Code :</b>
            <p>{v.code}</p>
          </div>
          <div className="row">
            <b style={style}>Start Date :</b>
            <p>{v.from_date}</p>
          </div>
          <div className="row">
            <b style={style}> Expiry Date :</b>
            <p>{v.expire_date}</p>
          </div>
          <div className="row">
            <b style={style}> Months:</b>
            <p>{v.month}</p>
          </div>
          <div className="row">
            <b style={style}> Price:</b>
            <p>{v.price}$</p>
          </div>
          <div className="row">
            <b style={style}>Status :</b>
            <Tag color={'green'}>{v.status==true?'active':'disabled'}</Tag>
          </div>
          <div className="row">
            <b style={style}>Created</b>
            <p>{moment(v.createdAt).format('DD:MM:YYYY MM:HH A')}.   </p>
          </div>
          <div className="row">
            <b style={style}>Modified</b>
            <p>{moment(v.updatedAt).format('DD:MM:YYYY MM:HH A')}.   </p>
          </div>
          <div className="row">
            <b style={style}>Used</b>
            <Tag color={v.used==false?'green':'red'}>{v.used==true?'Yes':'No'}</Tag>
          </div>
          <div className="row">
            <b style={style}>Actions</b>
          </div>
          <div className="row">
            <Tooltip title='status change'>
              <b style={style3} onClick={()=>{
                 axios.put('https://activatewm.com/demo/v1/license/update?_id='+v._id,{
                  status:v.status?false:true
                },{
                   headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  }
                }).then(data=>{
                  if(data.data.success){
                    fetch()
                  }
                }).catch(err=>{
                  err.response.status===400?message.warning('failed'):message.error('Server error occur')
                })
              }}><CheckOutlined/></b>
              </Tooltip>
             <Tooltip title="Edit">
              <b style={style3} onClick={()=>{
                navigation('/license/create',{
                  state:{
                    c_id:location.state.c_id,
                    d_id:location.state.d_id,
                    data:v
                  }
                })
              }}><EditFilled/></b>
              </Tooltip>
             <Tooltip title="Delete">
              <b style={style3}><DeleteFilled onClick={()=>{
                axios.delete('https://activatewm.com/demo/v1/license/delete?_id='+v._id).then(res=>{
                  if(res.data.success){
                    message.success('Deleted')
                    fetch()
                  }else{
                    message.error('Sorry Check again')
                  }
                }).catch(err=>{
                  message.error('Internal Server issue')
                })
              }}/></b>
              </Tooltip>
            </div>
          
        </div>
        })
      }
       </div>
      <Pagination 
      style={{right:60,position:'absolute'}}
      onChange={(page)=>{
       setMax(page*pageSize)
       setMin((page-1)*pageSize)
      }} 
      pageSize={pageSize}
      defaultCurrent={1} 
      total={data.length} 
      />
          </>
        }
      </div>
      
    </div>
    )
}
export default Devices