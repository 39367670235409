import React, { useState,useEffect } from "react"
import "antd/dist/antd.css";
import {ArrowLeftOutlined} from '@ant-design/icons'
import { Form, Input, Button, Select, Tooltip, Divider, Space,message, DatePicker, InputNumber, Switch } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import moment from "moment";
require('./sub.routes.css')
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const LicenseCreate=()=>{
    const [form] = Form.useForm();
    const navigation=useNavigate()
    const location=useLocation()
    const [c_id,setClientId]=useState(location.state.c_id?location.state.c_id:"")
    const [d_id,setDealerId]=useState(location.state.d_id?location.state.d_id:"")
    const [add,setAdd]=useState(true)
    const [typeData,setTypeData]=useState([])
    const [softwareType,setSoftwareType]=useState([])
    const alphanumeric_unique= ()=>{
      return (Math.random() + 1).toString(36).substring(2,10)
  }
 
  const [licensecode,setLicenseCode]=useState(alphanumeric_unique())
    const onFinish = (v) => {
      setAdd(false)
     const hide = message.loading('Action in progress..', 0);
     if(location.state.data){
      axios.put('https://activatewm.com/demo/v1/license/update?_id='+location.state.data._id,{
        code: v.licensecode,
        license_name:v.Name,
        from_date:v.start.format('YYYY-MM-DD'),
        expire_date: " ",
        month: v.months,
        price: v.price,
        customer_id: c_id,
        dealer_id: d_id,
        device_type:v.type ,
        status: true,
        used: v.use,
        software_type:v.soft_type,
        status:v['Status']=="active"?true:false
      }).then(res=>{
        setTimeout(hide,100)
        if(res.data.success){
          
          setAdd(true)
          message.success('Update  success')
        }else{
          message.error("Failed to Update")
          setAdd(true)
        }
      }).catch(err=>{
        setAdd(true)
        setTimeout(hide,100)
        err.message==='Request failed with status code 400'? message.info("Email already Registered"):message.error('Internal server issue')
      })
     }else{
      axios.post('https://activatewm.com/demo/v1/license/create',{
        code: v.licensecode,
        license_name:v.Name,
        from_date:v.start.format('YYYY-MM-DD'),
        expire_date: " ",
        month: v.months,
        price: v.price,
        customer_id: c_id,
        dealer_id: d_id,
        device_type:v.type ,
        status: true,
        used: v.use,
        software_type:v.soft_type,
        status:v['Status']=="active"?true:false
      }).then(res=>{
        setTimeout(hide,100)
        if(!res.data.success){
          onReset()
          setAdd(true)
          message.success('Created')
          setAdd(true)
        }else{
         // message.error("Email already Registered")
        }
      }).catch(err=>{
        setAdd(true)
        setTimeout(hide,100)
        err.message==='Request failed with status code 400'? message.info("Email already Registered"):message.error('Internal server issue')
      })
     }
      };
    const fetch=()=>{
        axios.get('https://activatewm.com/demo/v1/options/devicesystem/get') .then(res=>{
          setTypeData(res.data)
        }).catch(err=>{
          console.log(err)
          message.error('internal error')
        })
        axios.get('https://activatewm.com/demo/v1/options/softwaretype/get') .then(res=>{
          setSoftwareType(res.data)
        }).catch(err=>{
          console.log(err)
          message.error('internal error')
        })
      
      }
      useEffect(()=>{
       fetch()
      },[])  

const onReset = () => {
        setLicenseCode(alphanumeric_unique())
        form.resetFields();
      };
    
    return (
      <div className="main-create  w3-card ">
      <div className="header">
      <div className="back">
      <Tooltip title="Back">
      <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={()=>{
         navigation('/license',{replace:true,state:{
          c_id:location.state.c_id,
          d_id:location.state.d_id
        }})
      }}/>
    </Tooltip>    
      </div>
      </div>
      <Space />
      <Space />
      <Divider /> 
      <div className="data-form">
      <Form 
       initialValues={location.state.data?{'Name':location.state.data.license_name,
       'licensecode':location.state.data.code,"start":moment(location.state.data.from_date,'YYYY-MM-DD'),
       "months":location.state.data.months,
       "Status":location.state.data.status?'active':"deactive",
       "use":location.state.data.used,
       "soft_type":location.state.data.softwaretype?location.state.data.softwaretype._id:"",
       "price":location.state.data.price,"type":location.state.data.devicesType?location.state.data.devicesType._id:""}
       :{"start":moment(),"licensecode":alphanumeric_unique().toLocaleUpperCase()}}
      {...layout} 
      form={form} 
      labelAlign='left'
      name="control-hooks" 
      onFinish={onFinish}>
      <Form.Item
      style={{ marginBottom: 4 }}
      name="type"
      label="Select Device Type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a device type"
        >
         {
           typeData.map(item=>{
             if(item.status){
              return <Option value={item._id}>{item.name}</Option>
             }
           })
         }
        </Select>
      </Form.Item>  
      <Form.Item
      style={{ marginBottom: 4 }}
      name="soft_type"
      label="Select Software Type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a Software type"
        >
         {
           softwareType.map(item=>{
             if(item.status){
              return <Option value={item._id}>{item.name}</Option>
             }
           })
         }
        </Select>
      </Form.Item> 
     <Form.Item
     style={{ marginBottom: 4 }}
        name="Name"
        label="License Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
      <Input />
      </Form.Item>
      <Form.Item

      style={{ marginBottom: 4 }}
        name="licensecode"
        label="License code"
        
        rules={[
          {
            required: true,
          },
        ]}
      ><Input readOnly />
      </Form.Item> 
      <Form.Item
      initialValue={1}
      style={{ marginBottom: 4 }}
         name="months"
         label="Total Months"
         rules={[
           {
             required: true,
           },
         ]}
       >
      <InputNumber min={1}    />
       </Form.Item> 
      <Form.Item 
        style={{ marginBottom: 4 }}
      name={'start'}
      label="Start Date"
      rules={[
        {
          required: true,
          message: 'Select start Date!',
        },
      ]}
      >
        <DatePicker/>
      </Form.Item>  
      
      <Form.Item
      initialValue={0}
      style={{ marginBottom: 4 }}
         name="price"
         label="Enter price"
         rules={[
           {
             required: true,
           },
         ]}
       >
      <InputNumber min={1}   />
       </Form.Item>   
      <Form.Item
        style={{ marginBottom: 4 }}
        name="Status"
        label="Status"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
        >
          <Option value="active">Active</Option>
          <Option value="deactive">Deactive</Option>
        </Select>
      </Form.Item>
    
     
      <Form.Item 
        style={{ marginBottom: 4 }}

      initialValue={false} name='use' label="Used" valuePropName="checked"
              rules={[
                {
                  required: true,
                },
              ]}
      >
        <Switch />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" disabled={!add} htmlType="submit" style={{marginRight:10}}>
          {location.state.data?"Update":"Add"} 
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
       
      </Form.Item>

    </Form>
      </div>
     </div>
      )
}
export default LicenseCreate

