import React, { useEffect, useState } from "react"
import {Empty, Input, message, Pagination,Tag, Tooltip} from 'antd';
import "antd/dist/antd.css";
import {PlusOutlined,CheckOutlined,EditFilled,DeleteFilled} from '@ant-design/icons'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Item from "antd/lib/list/Item";
import { useCookies } from "react-cookie";
const style = { width:120,marginRight:40,paddingLeft:10};
const style2 = { backgroundColor:'#dd4',paddingLeft:6,paddingRight:20,alignItems: 'center'};
const style3={width:70,textAlign:'center',fontSize:16,backgroundColor:'#dd4',marginRight:10,borderRadius:20,cursor:'pointer'}
require('./routes.css')

const Dealers=()=>{

  const[data,setData]=useState([])
  const[temp,setTemp]=useState([])
  const fetch=()=>{
    const hide = message.loading('Action in progress..', 0);
    axios.get('https://activatewm.com/demo/v1/dealer/get')
    .then(res=>{
      setTimeout(hide,100)
      setData(res.data)
      setTemp(res.data)
    })
    .catch(err=>{
      setTimeout(hide,100)
      message.error('internal error')
    })
  }


  useEffect(()=>{
   fetch()
  },[])
  const navigation=useNavigate()
  const [min,setMin]=useState(0)
  const [max,setMax]=useState(4)
  const pageSize=4
  const onSearch = value => {
    if(value.length==0){
      setData(temp)
    }else{
     let matched=temp.filter((item)=>{
        if(item.dealer_name.match(value) ||item.email.match(value)){
          return item
        }
      })
      setData(matched)
    }
  };
    return (
      <div className="main main-dealer w3-card" >
      <div className="header">
      <h5>Dealers</h5>
      <div className="create">
      <PlusOutlined className='plus' onClick={()=>{
        navigation('/dealers/create')
        }} />
      <Input.Search placeholder="search name/email" allowClear onSearch={onSearch} style={{ width: 200 }} />
      </div>
      </div>
      <div >
        {
          data.length==0?<Empty/>:
          <>
          <div className="card" style={{marginBottom:5}}>
       {
        data.slice(min,max).map((v,i)=>{
          
          return   <div 
          className="client-card w3-card "
          style={{ width: 330,height:500,padding:12,paddingTop:40,margin:10}}>
             <div className="row">
            <b>Total Devices</b>
            <p style={style2}>{v.licenses.count}</p>
          </div>
          <div className="row">
            <b >Used Devices</b>
            <p style={style2}>{v.licenses.used}</p>
          </div>
          <div className="row">
            <b >Expired Devices</b>
            <p style={{backgroundColor:'red',paddingLeft:6,paddingRight:20,alignItems: 'center', }}>{v.licenses.expired}</p>
          </div> 
          <div className="row">
            <b > Dealer Name :</b>
            <p>{v.dealer_name}</p>
          </div>
          <div className="row">
            <b >Email :</b>
            <p>{v.email}</p>
          </div>
          <div className="row">
            <b >Mobile :</b>
            <p>{v.mobile}</p>
          </div>
          <div className="row">
            <b >DealerShip :</b>
            <Tag>{v.dealership}%</Tag>
          </div>
          <div className="row">
            <b >Status :</b>
            <Tag color={'green'}>{v.status==true?'active':'disabled'}</Tag>
          </div>
          <div className="row">
            <b >Created</b>
            <p>{moment(v.createdAt).format('DD:MM:YYYY MM:HH A')}.   </p>
          </div>
          <div className="row">
            <b >Modified</b>
            <p>{moment(v.updatedAt).format('DD:MM:YYYY MM:HH A')}.   </p>
          </div>
          <div className="row">
            <b >Actions</b>
          </div>
          <div className="row">
          <Tooltip title='Status change'>
              <b style={style3} onClick={()=>{
                 axios.put('https://activatewm.com/demo/v1/dealer/update',{
                  _id:v._id,
                  data:{
                  status:v.status?false:true
                }
                },{
                   headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  }
                }).then(data=>{
                  if(data.data.success){
                    fetch()
                  }
                }).catch(err=>{
                  err.response.status===400?message.warning('failed'):message.error('Server error occur')
                })
              }}><CheckOutlined/></b>
              </Tooltip>
              <Tooltip title='Edit'>
              <b style={style3} onClick={()=>{
                navigation('/dealers/create',{
                  state:{
                  data:v
                }
                })
              }}><EditFilled/></b>
              </Tooltip>
              <Tooltip title='Devices'>
              <em style={style3} onClick={()=>{
                navigation('/license',{
                  state:{
                    c_id:"",
                    d_id:v._id
                  }
                })   
              }}>Devices</em>
              </Tooltip>
              <Tooltip title='Delete'>
              <b style={style3}><DeleteFilled onClick={()=>{
                axios.delete('https://activatewm.com/demo/v1/dealer/delete?_id='+v._id).then(res=>{
                  if(res.data.success){
                    message.success('Deleted')
                    fetch()
                  }else{
                    message.error('Sorry Check again')
                  }
                }).catch(err=>{
                  message.error('Internal Server issue')
                })
              }}/></b>
              </Tooltip>
            </div>
        </div>
        })
      }
       </div>
      <Pagination 
      style={{right:60,position:'absolute'}}
      onChange={(page)=>{
       setMax(page*pageSize)
       setMin((page-1)*pageSize)
      }} 
      pageSize={pageSize}
      defaultCurrent={1} 
      total={data.length} 
      />
          </>
        }
       

      </div>
     </div>
      )
}
export default Dealers

